import React from 'react';
import classNames from 'classnames';

const FactItem = ({ label, value }) => (
  <li>
    <div className="label">{label}</div>
    <div className="value">{value}</div>
  </li>
);

const FactList = ({ children, className }) => (
  <ul className={classNames('fact-list', className)}>{children}</ul>
);

export { FactItem, FactList };
export default FactList;
