import React from 'react';
import Navbar from './Navbar';

const Layout = ({ children, shadowBottom }) => {
  return (
    <>
      <div className="white-shadow top" />
      {shadowBottom && <div className="white-shadow bottom" />}
      <Navbar />
      <main>{children}</main>
    </>
  );
};

export default Layout;
