import React from 'react';
import classNames from 'classnames';
import { Link, useRouteMatch } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/logo_2.svg';

const NavLink = ({ to, ...props }) => {
  const match = useRouteMatch();
  const className = classNames('nav-item', {
    active: match.path === to,
  });

  return (
    <li className={className}>
      <Link className="nav-link" to={to} {...props} />
    </li>
  );
};

const Navbar = () => (
  <nav className="navbar fixed-top">
    <Link className="navbar-brand logo" to="/properties">
      <Logo />
      <div>
        ZD<span>45</span>
      </div>
    </Link>
    <div className="container navbar-menu">
      <ul className="navbar-nav">
        <NavLink to="/properties">Properties</NavLink>
        <NavLink to="/fact-sheet">Fact Sheet</NavLink>
      </ul>
    </div>
    <ul className="navbar-nav right">
      <li className="nav-item logout">
        <Link className="nav-link" to="/logout">
          Logout
        </Link>
      </li>
    </ul>
  </nav>
);

export default Navbar;
