import { gql } from 'apollo-boost';

const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      token
      user
    }
  }
`;

const PROPERTY_LIST = gql`
  query PropertyList {
    propertyList {
      id
      city
      price
      rent
      income
      fico
      lat
      lng
      pictureUrl
    }
  }
`;

export { LOGIN, PROPERTY_LIST };
