import React from 'react';
import Layout from 'components/Layout';
import About from './About';
import SuperPrime from './SuperPrime';
import Salaries from './Salaries';
import KeyFacts from './KeyFacts';
import Manager from './Manager';

const FactSheet = () => (
  <Layout shadowBottom>
    <div className="fact-sheet container">
      <About />
      <SuperPrime />
      <Salaries />
      <KeyFacts />
      <Manager />
    </div>
    <footer>2020 (C) Copyright. ZD45. All Rights Reserved.</footer>
  </Layout>
);

export default FactSheet;
