import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { withTracker } from './components/withTracker';
import Login from './screens/Login';
import Properties from './screens/Properties';
import FactSheet from './screens/FactSheet';
import { Logout, PrivateRoute } from './utils/auth';

const App = () => (
  <Router>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/logout" component={Logout} />
      <PrivateRoute path="/properties" component={withTracker(Properties)} />
      <PrivateRoute path="/fact-sheet" component={withTracker(FactSheet)} />
      <Redirect from="/" to="/properties" />
    </Switch>
  </Router>
);

export default App;
