import React from 'react';
import decode from 'jwt-decode';
import { Redirect, Route } from 'react-router-dom';

const getToken = () => localStorage.getItem('token');
const setToken = token => localStorage.setItem('token', token);
const clearToken = () => localStorage.removeItem('token');

const decodeToken = key => {
  try {
    return decode(key);
  } catch (e) {
    return null;
  }
};

const getAuth = () => decodeToken(getToken());

const withAuth = BaseComponent => props => {
  const token = getToken();

  return <BaseComponent {...props} authenticated={!!token} />;
};

const PrivateRoute = ({ component: Component, ...props }) => {
  const user = getAuth();
  const isAuthenticated = !!user;

  const render = renderProps => {
    if (!isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: renderProps.location },
          }}
        />
      );
    }

    return <Component {...renderProps} />;
  };

  return <Route {...props} render={render} />;
};

const Logout = () => {
  const token = getToken();

  if (token) {
    window.location.reload();
    clearToken();
  }

  return <Redirect to="/login" />;
};

export { getToken, setToken, decodeToken, withAuth, Logout, PrivateRoute };
