import React from 'react';
import classNames from 'classnames';
import imgChart from 'assets/chart@2x.png';

const data = [
  { rank: 1, area: 'San Francisco Metro Area', growth: '4.3%' },
  { rank: 2, area: 'Austin Metro Area', growth: '4.0%' },
  { rank: 3, area: 'Seattle Metro Area', growth: '4.0%' },
  { rank: 4, area: 'Pittsburgh Metro Area', growth: '3.7%' },
  { rank: 5, area: 'Cleveland Metro Area', growth: '3.6%' },
];

const Salaries = () => (
  <div className="fact-section">
    <h3 className="mb-1">fastest growing salary</h3>
    <div className="headline mb-1">
      Salaries in Bay Area have fastest growth in US
    </div>
    <p>
      Some salaries rise faster than averages - median pay at Google rose 25
      percent last year.
    </p>
    <div className="row mt-5">
      <div className="col-12 col-md-6">
        <legend>Q3 2019 Year-Over-Year Wage Growth</legend>
        <table className="table">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Metro Area</th>
              <th>Wage Growth</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => (
              <tr
                key={item.rank}
                className={classNames({ highlight: item.rank === 1 })}
              >
                <td>{item.rank}</td>
                <td>{item.area}</td>
                <td>{item.growth}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="col-12 col-md-6">
        <legend>Salary trajectory within Google</legend>
        <img className="chart" src={imgChart} />
      </div>
    </div>
  </div>
);

export default Salaries;
