import React from 'react';
import imgProperties from 'assets/properties@2x.jpg';
import imgCompanies from 'assets/companies@2x.png';

const SuperPrime = () => (
  <div className="fact-section">
    <div className="row">
      <div className="col-12 col-md-6">
        <h3>45 Super Prime Households</h3>
        <img className="companies" src={imgCompanies} alt="" width={334} />
        <div className="info">
          <div className="pr-5">
            <div className="label">Median HH Income</div>
            <div className="value">$340K</div>
          </div>
          <div className="pr-5">
            <div className="label">Median FICO</div>
            <div className="value">740</div>
          </div>
          <div>
            <div className="label">Product NPS</div>
            <div className="value">100</div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <h3>45 Super Prime properties</h3>
        <img src={imgProperties} alt="" />
        <div className="more">
          <a href="/properties">+ 36 more</a>
        </div>
      </div>
    </div>
  </div>
);

export default SuperPrime;
