import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { useQuery } from 'react-apollo-hooks';
import { PROPERTY_LIST } from 'api/common';
import Layout from 'components/Layout';
import PropertyPlaceholder from './PropertyPlaceholder';
import PropertyCard from './PropertyCard';
import Map from './Map';

const LoadingProperties = () => (
  <>
    <PropertyPlaceholder />
    <PropertyPlaceholder />
    <PropertyPlaceholder />
    <PropertyPlaceholder />
  </>
);

const Properties = () => {
  const propertiesRef = useRef();
  const { loading, data } = useQuery(PROPERTY_LIST);
  const [selected, setSelected] = useState(null);
  const [center, setCenter] = useState({
    lat: 37.60518,
    lng: -122.254914,
  });

  const { propertyList = [] } = data || {};
  const refs = propertyList.reduce((acc, item) => {
    acc[item.id] = React.createRef();
    return acc;
  }, {});

  const handleMarkerClick = ({ id }) => {
    setSelected(id);
    const card = refs[id].current;
    const list = propertiesRef.current;
    const offsetTop = card.offsetTop - 250;
    const offsetLeft = card.offsetLeft - list.offsetWidth / 2 + 110;

    list.scrollTo({
      top: offsetTop,
      left: offsetLeft,
      behavior: 'smooth',
    });
  };

  const handleCardClick = ({ id, lat, lng }) => {
    const newLat = lat - 0.2;
    const newLon = lng;
    setSelected(id);
    setCenter({ lat: newLat, lng: newLon });
  };

  const animatedClass = classNames({
    animated: loading,
  });

  return (
    <Layout>
      <div className="properties">
        <div className="properties--listings">
          <div className="header placeholder">
            <div>
              <h2 className={animatedClass}>Properties</h2>
            </div>
            <p className={animatedClass}>
              Showing all {propertyList.length} properties in the fund
            </p>
          </div>
          <div className="content" ref={propertiesRef}>
            <div className="content-inner">
              {loading ? (
                <LoadingProperties />
              ) : (
                propertyList.map(item => (
                  <PropertyCard
                    key={item.id}
                    innerRef={refs[item.id]}
                    selected={selected === item.id}
                    data={item}
                    onClick={handleCardClick}
                  />
                ))
              )}
            </div>
          </div>
        </div>
        <div className="properties--map">
          <Map
            zoom={10}
            center={center}
            markers={propertyList}
            selected={selected}
            onMarkerClick={handleMarkerClick}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Properties;
