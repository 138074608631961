import React from 'react';

const About = () => (
  <div className="fact-section">
    <h3>ABOUT ZD45</h3>
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="headline">
          ZD45 is the only way to invest in Silicon Valley talent and properties
          at the same time
        </div>
      </div>
      <div className="col-12 col-md-6">
        <ul className="styled-list">
          <li>
            Diversified set of 45 high-quality homes across the Bay Area with
            $330K median income and 740 credit score.
          </li>
          <li>
            Assets already acquired so your money goes to work right away.
          </li>
          <li>
            A first-of-its-kind real estate fund with a portfolio constructed by
            collective intelligence and automated underwriting.
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default About;
