import React from 'react';
import imgManager from 'assets/manager.jpg';

const Manager = () => {
  return (
    <div className="fact-section">
      <h3>Fund Manager: Scott Lustig</h3>
      <div className="manager">
        <img src={imgManager} alt="Photo" width="195" />

        <div className="manager-info">
          <p>
            Scott is Director of Capital Markets at ZeroDown. Previously, he was
            a Managing Director at Fortress Investment Group, a leading, highly
            diversified global investment manager with approximately $42 billion
            of assets under management, where he invested throughout the capital
            structure in financial services companies as part of the $1.3
            billion AUM Fortress Strategic Capital business.
          </p>

          <p>
            Prior to Fortress, Scott was a Managing Director and co-founder of
            Culpeper Capital, a joint-venture with Fortress. Mr. Lustig’s
            financial services experience encompasses over $5 billion of debt
            and equity raised or invested in support of dozens of control and
            non-control equity and credit investments.
          </p>

          <p>
            In addition, he has served on numerous specialty finance company
            boards of directors during his career. He holds a B.S in Economics
            from the Wharton School of Business and a B.A.S from the School of
            Engineering and Applied Science at the University of Pennsylvania
          </p>
        </div>
      </div>
    </div>
  );
};

export default Manager;
