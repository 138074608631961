import React from 'react';
import classNames from 'classnames';

const PropertyCard = ({ innerRef, selected, data, onClick }) => (
  <div
    ref={innerRef}
    className={classNames('property card', { selected })}
    onClick={() => onClick(data)}
  >
    <img className="card-img-top" src={data.pictureUrl} />
    <div className="card-body">
      <h3>{data.city}</h3>
    </div>
    <div className="card-body">
      <div className="line">
        <div>
          <div className="label">Asset purchase price</div>
          <div className="value">{data.price}</div>
        </div>
        <div>
          <div className="label">Monthly payment</div>
          <div className="value">
            {data.rent}
            <small>/mo</small>
          </div>
        </div>
      </div>
    </div>
    <div className="card-body">
      <div className="line">
        <div>
          <div className="label">Annual income</div>
          <div className="value">{data.income}</div>
        </div>
        <div>
          <div className="label">FICO</div>
          <div className="value">{data.fico}</div>
        </div>
      </div>
    </div>
    <div className="card-body payments">
      <div className="value">100% on time payments</div>
    </div>
  </div>
);

export default PropertyCard;
