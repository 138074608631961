import React from 'react';
import classNames from 'classnames';
import GoogleMapReact from 'google-map-react';
import { ReactComponent as MarkerImg } from 'assets/marker.svg';

const Marker = ({ selected, onClick }) => (
  <div className={classNames('marker', { selected })}>
    <MarkerImg onClick={onClick} />
  </div>
);

const setStyle = ({ map, maps }) => {
  const colors = {
    white: '#ffffff',
    gray: '#f4f4f4',
    yellow: '#ffeaab',
    purple: '#e3d6eb',
    text: '#bcbcbc',
    gray2: '#e9e9e9',
  };

  const styledMapType = new maps.StyledMapType(
    [
      { elementType: 'geometry', stylers: [{ color: colors.gray }] },
      { elementType: 'labels.text.fill', stylers: [{ color: colors.text }] },
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: colors.white }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: colors.gray2 }],
      },
      {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry',
        stylers: [{ color: colors.yellow }],
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: colors.purple }],
      },
    ],
    { name: 'Styled Map' },
  );

  map.mapTypes.set('styled_map', styledMapType);
  map.setMapTypeId('styled_map');
};

const Map = ({ markers, selected, center, zoom, onMarkerClick }) => {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyB8amDnCz-LfKKK-GdszeN1qf7ntz-4L7g' }}
      center={center}
      defaultZoom={zoom}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={setStyle}
      options={{
        disableDefaultUI: true,
        maxZoom: zoom,
        minZoom: zoom,
      }}
    >
      {markers.map(item => (
        <Marker
          key={item.id}
          lat={item.lat}
          lng={item.lng}
          selected={item.id === selected}
          onClick={() => onMarkerClick(item)}
        />
      ))}
    </GoogleMapReact>
  );
};

export default Map;
