import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { getToken, decodeToken } from 'utils/auth';
import config from 'config';

const getUser = () => {
  return decodeToken(getToken()) || {};
};

ReactGA.initialize(config.gaCode, {
  debug: process.env.NODE_ENV !== 'production',
  titleCase: false,
  gaOptions: {
    userId: getUser().id,
  },
});

export const withTracker = WrappedComponent => {
  const trackPage = page => {
    const user = getUser();
    ReactGA.set({
      page,

      // You can add more parameters here.
      // Use dimensionN for strings and metricN for numbers
      dimension1: user.id.toString(),
    });

    ReactGA.pageview(page);
  };

  return props => {
    useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname,
    ]);

    return <WrappedComponent {...props} />;
  };
};
