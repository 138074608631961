import React from 'react';

const PropertyPlaceholder = () => (
  <div className="placeholder property card">
    <div className="card-img-top" />
    <div className="card-body">
      <h3 className="animated">AAAAAAAAAAAAAA</h3>
      <div className="line">
        <div>
          <div className="label animated">Aaaaaaaaaaaa</div>
          <div className="price animated">$100000000</div>
        </div>
        <div>
          <div className="label animated">Aaaaaaaaaaaa</div>
          <div className="price animated">$1000000</div>
        </div>
      </div>
    </div>
    <div className="card-body">
      <div className="line">
        <div>
          <div className="label animated">Aaaaaaaaaaaa</div>
          <div className="price animated">5000%</div>
        </div>
        <div>
          <div className="label animated">Aaaaaaaaaaaa</div>
          <div className="price animated">100%</div>
        </div>
      </div>
      <div className="progress animated" />
    </div>
  </div>
);

export default PropertyPlaceholder;
