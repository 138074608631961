import React, { useState } from 'react';
import ReactGA from 'react-ga';
import PinInput from 'react-pin-input';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-apollo-hooks';
import { LOGIN } from 'api/common';
import { setToken } from 'utils/auth';
import imgLogo from 'assets/logo.svg';

const useSubmit = () => {
  const history = useHistory();
  const [submit] = useMutation(LOGIN);

  return async input => {
    try {
      const { data } = await submit({ variables: { input } });
      const { token, user } = data.login;

      setToken(token);
      ReactGA.ga('set', 'userId', user.id);
      history.push('/properties');
    } catch (e) {
      throw new Error(e.message.replace('GraphQL error: ', ''));
    }
  };
};

const Login = () => {
  const [error, setError] = useState(false);
  const submit = useSubmit();
  const clearError = () => setError(false);
  const handleComplete = async code => {
    try {
      await submit({ code });
    } catch (e) {
      setError(true);
    }
  };

  return (
    <main>
      <section className="login">
        <div className="container">
          <div className="logo">
            <img src={imgLogo} />
          </div>
          <form>
            <label>Enter your 8-digit passcode</label>
            <PinInput
              className="pincode"
              length={8}
              focus
              type="numeric"
              onChange={clearError}
              onComplete={handleComplete}
            />
            {error && <div className="error shake">INCORRECT PASSCODE</div>}
          </form>
        </div>
      </section>
    </main>
  );
};

export default Login;
