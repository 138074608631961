import React from 'react';
import { FactItem, FactList } from './FactList';

const KeyFacts = () => (
  <div className="fact-section">
    <h3>Key Facts</h3>
    <div className="row">
      <div className="col-12 col-md-6">
        <FactList>
          <FactItem label="Assets" value="$48.4M" />
          <FactItem label="Asset class" value="SFR" />
          <FactItem label="Acquisition year" value="2019" />
        </FactList>
      </div>
      <div className="col-12 col-md-6 m-0">
        <FactList className="merge-top">
          <FactItem label="On-time payments" value="100%" />
          <FactItem label="Inspection" value="100%" />
          <FactItem label="Appraisal" value="100%" />
        </FactList>
      </div>
    </div>
  </div>
);

export default KeyFacts;
