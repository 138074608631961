import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  HttpLink,
} from 'apollo-boost';
import * as auth from 'utils/auth';
import { getServerUrl } from 'utils/misc';

const uri = getServerUrl() + '/api';
const httpLink = new HttpLink({
  uri,
});

const authLink = new ApolloLink((operation, forward) => {
  const token = auth.getToken();

  token &&
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
